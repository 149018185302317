
import toast from "react-hot-toast";


const AxiosErrorHandler = async (err, options) => {

   if (err.response || err.request) {

      if (err.response && err.response.status && err.response.status === 419){
         window.localStorage.removeItem(`accessToken`);
         window.document.location = "/";
      }
      //expired JWT          
      else
         return await ToastMessage(err, options);
   }
   else { toast.error("System Error : " + err.message); return true; }

}

const ToastMessage = async (err, options) => {
  
   let errorMessage = "";

   if (err.response) {
      errorMessage = "Server status : " + err.response.status;
   }
   else if (err.request)
      errorMessage = "There was no response from backserver.";

   if (err.response && err.response.data.code && err.response.data.status && err.response.data.message) {
      errorMessage = err.response.data.message;

      if (err.response.data.code === -100) {
         if (options.PostNotFoundFunction){
           
           options.PostNotFoundFunction(err.response.data);
         }
         if (!options.supressNotFound)
            toast(errorMessage);


      }
      else
         toast.error(errorMessage);

   }
   else {
      if (errorMessage !== "")
         toast.error(errorMessage);
   }
   return true;
}

export default AxiosErrorHandler;