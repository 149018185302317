// assets
import {
    IconSettings
} from '@tabler/icons';
import {
    IconMessages
} from '@tabler/icons';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import AppsIcon from '@mui/icons-material/Apps';
import DataArrayIcon from '@mui/icons-material/DataArray';
import RememberMeIcon from '@mui/icons-material/RememberMe';

// constant
const icons = {
    IconSettings,
    IconMessages,
    ImportContactsIcon,
    AppsIcon,
    DataArrayIcon,
    RememberMeIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
const spAdminMenuItems = {
    id: 'settings-parent',
    title: 'Administrator Menu',
    type: 'group',
    children: [
        
        {
            id: 'conversation-parent',
            title: 'Conversations',
            type: 'collapse',
            breadcrumbs: true,
            icon: icons.IconMessages,
            iconColor: '#000080',
            children: [
                {
                    id: 'conversation',
                    title: 'Inbox',
                    
                    type: 'item',
                    url: '/chat',
                    breadcrumbs: false
                },
                {
                    id: 'closedconversation',
                    title: 'Closed',
                    
                    type: 'item',
                    url: '/closed-chats',
                    breadcrumbs: false
                },
                {
                    id: 'openconversation',
                    title: 'All Open',
                    
                    type: 'item',
                    url: '/all-chats',
                    breadcrumbs: false
                },
                {
                    id: 'allclosedconversation',
                    title: 'All Closed',
                    
                    type: 'item',
                    url: '/all-closed-chats',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'application-parent',
            title: 'Application',
            type: 'collapse',
            icon: icons.AppsIcon,
            iconColor: '#252d64',
            breadcrumbs: false,
            children: [{
                    id: 'agents',
                    title: 'Agents',
                    type: 'item',
                    url: '/agents',
                    breadcrumbs: false,
                },
                {
                    id: 'companies',
                    title: 'Companies',
                    type: 'item',
                    url: '/companies',
                    breadcrumbs: false,
                },
                {
                    id: 'businessnumbers',
                    title: 'Business Numbers',
                    type: 'item',
                    url: '/business-numbers',
                    breadcrumbs: false,
                },
                {
                    id: 'distributionlist',
                    title: 'Distribution List',
                    type: 'item',
                    url: '/distribution-list',
                    breadcrumbs: false,
                },
                {
                    id: 'agentgroups',
                    title: 'Agent Groups',
                    type: 'item',
                    url: '/agent-groups',
                    breadcrumbs: false,
                },
                /*
                {
                    id: 'autoreplytexts',
                    title: 'Auto Replies (Text)',
                    type: 'item',
                    url: '/auto-reply-texts',
                    breadcrumbs: false,
                },
                */
                {
                    id: 'autoreplytime',
                    title: 'Auto Replies (Time)',
                    type: 'item',
                    url: '/auto-reply-times',
                    breadcrumbs: false,
                },
                {
                    id: 'autoreplydelayed',
                    title: 'Auto Replies (Delayed)',
                    type: 'item',
                    url: '/auto-reply-delayed',
                    breadcrumbs: false,
                },
                {
                    id: 'apirequests',
                    title: 'API Requests',
                    type: 'item',
                    url: '/api-requests',
                    breadcrumbs: false,
                },
            ]
        },
        {
            id: 'data-parent',
            title: 'Data',
            type: 'collapse',
            icon: icons.DataArrayIcon,
            iconColor: '#252d64',
            breadcrumbs: false,
            children: [{
                id: 'mobileenduser',
                title: 'Mobile End Users',
                type: 'item',
                url: '/mobile-end-user',
                breadcrumbs: false,
                iconColor: '#000080'
            }]
        }
            
    ]
};

const spAdminMenuItemsNoDelay = {
    id: 'settings-parent',
    title: 'Administrator Menu',
    type: 'group',
    children: [
        
        {
            id: 'conversation-parent',
            title: 'Conversations',
            type: 'collapse',
            breadcrumbs: true,
            icon: icons.IconMessages,
            iconColor: '#000080',
            children: [
                {
                    id: 'conversation',
                    title: 'Inbox',
                    
                    type: 'item',
                    url: '/chat',
                    breadcrumbs: false
                },
                {
                    id: 'closedconversation',
                    title: 'Closed',
                    
                    type: 'item',
                    url: '/closed-chats',
                    breadcrumbs: false
                },
                {
                    id: 'openconversation',
                    title: 'All Open',
                    
                    type: 'item',
                    url: '/all-chats',
                    breadcrumbs: false
                },
                {
                    id: 'allclosedconversation',
                    title: 'All Closed',
                    
                    type: 'item',
                    url: '/all-closed-chats',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'application-parent',
            title: 'Application',
            type: 'collapse',
            icon: icons.AppsIcon,
            iconColor: '#252d64',
            breadcrumbs: false,
            children: [{
                    id: 'agents',
                    title: 'Agents',
                    type: 'item',
                    url: '/agents',
                    breadcrumbs: false,
                },
                {
                    id: 'companies',
                    title: 'Companies',
                    type: 'item',
                    url: '/companies',
                    breadcrumbs: false,
                },
                {
                    id: 'businessnumbers',
                    title: 'Business Numbers',
                    type: 'item',
                    url: '/business-numbers',
                    breadcrumbs: false,
                },
                {
                    id: 'distributionlist',
                    title: 'Distribution List',
                    type: 'item',
                    url: '/distribution-list',
                    breadcrumbs: false,
                },
                {
                    id: 'agentgroups',
                    title: 'Agent Groups',
                    type: 'item',
                    url: '/agent-groups',
                    breadcrumbs: false,
                },
                /*
                {
                    id: 'autoreplytexts',
                    title: 'Auto Replies (Text)',
                    type: 'item',
                    url: '/auto-reply-texts',
                    breadcrumbs: false,
                },
                */
                {
                    id: 'autoreplytime',
                    title: 'Auto Replies (Time)',
                    type: 'item',
                    url: '/auto-reply-times',
                    breadcrumbs: false,
                },
                {
                    id: 'apirequests',
                    title: 'API Requests',
                    type: 'item',
                    url: '/api-requests',
                    breadcrumbs: false,
                },
            ]
        },
        {
            id: 'data-parent',
            title: 'Data',
            type: 'collapse',
            icon: icons.DataArrayIcon,
            iconColor: '#252d64',
            breadcrumbs: false,
            children: [{
                id: 'mobileenduser',
                title: 'Mobile End Users',
                type: 'item',
                url: '/mobile-end-user',
                breadcrumbs: false,
                iconColor: '#000080'
            }]
        }
            
    ]
};

const adminMenuItems = {
    id: 'settings-parent',
    title: 'Administrator Menu',
    type: 'group',
    children: [
        
        {
            id: 'conversation-parent',
            title: 'Conversations',
            type: 'collapse',
            breadcrumbs: true,
            icon: icons.IconMessages,
            iconColor: '#000080',
            children: [
                {
                    id: 'conversation',
                    title: 'Inbox',
                    
                    type: 'item',
                    url: '/chat',
                    breadcrumbs: false
                },
                {
                    id: 'closedconversation',
                    title: 'Closed',
                    
                    type: 'item',
                    url: '/closed-chats',
                    breadcrumbs: false
                },
                {
                    id: 'openconversation',
                    title: 'All Open',
                    
                    type: 'item',
                    url: '/all-chats',
                    breadcrumbs: false
                }]
            },
        {
            id: 'application-parent',
            title: 'Application',
            type: 'collapse',
            icon: icons.AppsIcon,
            iconColor: '#252d64',
            breadcrumbs: false,
            children: [{
                    id: 'agents',
                    title: 'Agents',
                    type: 'item',
                    url: '/agents',
                    breadcrumbs: false,
                },
                {
                    id: 'companies',
                    title: 'Companies',
                    type: 'item',
                    url: '/companies',
                    breadcrumbs: false,
                },
                {
                    id: 'businessnumbers',
                    title: 'Business Numbers',
                    type: 'item',
                    url: '/business-numbers',
                    breadcrumbs: false,
                },
                {
                    id: 'distributionlist',
                    title: 'Distribution List',
                    type: 'item',
                    url: '/distribution-list',
                    breadcrumbs: false,
                },
                {
                    id: 'agentgroups',
                    title: 'Agent Groups',
                    type: 'item',
                    url: '/agent-groups',
                    breadcrumbs: false,
                },
                /*
                {
                    id: 'autoreplytexts',
                    title: 'Auto Replies (Text)',
                    type: 'item',
                    url: '/auto-reply-texts',
                    breadcrumbs: false,
                },
                */
                {
                    id: 'autoreplytime',
                    title: 'Auto Replies (Time)',
                    type: 'item',
                    url: '/auto-reply-times',
                    breadcrumbs: false,
                },
                {
                    id: 'autoreplydelayed',
                    title: 'Auto Replies (Delayed)',
                    type: 'item',
                    url: '/auto-reply-delayed',
                    breadcrumbs: false,
                },
                {
                    id: 'apirequests',
                    title: 'API Requests',
                    type: 'item',
                    url: '/api-requests',
                    breadcrumbs: false,
                },
            ]
        },
        {
            id: 'data-parent',
            title: 'Data',
            type: 'collapse',
            icon: icons.DataArrayIcon,
            iconColor: '#252d64',
            breadcrumbs: false,
            children: [{
                id: 'mobileenduser',
                title: 'Mobile End Users',
                type: 'item',
                url: '/mobile-end-user',
                breadcrumbs: false,
                iconColor: '#000080'
            }]
        }
            
    ]
};

const adminMenuItemsNoDelay = {
    id: 'settings-parent',
    title: 'Administrator Menu',
    type: 'group',
    children: [
        
        {
            id: 'conversation-parent',
            title: 'Conversations',
            type: 'collapse',
            breadcrumbs: true,
            icon: icons.IconMessages,
            iconColor: '#000080',
            children: [
                {
                    id: 'conversation',
                    title: 'Inbox',
                    
                    type: 'item',
                    url: '/chat',
                    breadcrumbs: false
                },
                {
                    id: 'closedconversation',
                    title: 'Closed',
                    
                    type: 'item',
                    url: '/closed-chats',
                    breadcrumbs: false
                },
                {
                    id: 'openconversation',
                    title: 'All Open',
                    
                    type: 'item',
                    url: '/all-chats',
                    breadcrumbs: false
                }]
            },
        {
            id: 'application-parent',
            title: 'Application',
            type: 'collapse',
            icon: icons.AppsIcon,
            iconColor: '#252d64',
            breadcrumbs: false,
            children: [{
                    id: 'agents',
                    title: 'Agents',
                    type: 'item',
                    url: '/agents',
                    breadcrumbs: false,
                },
                {
                    id: 'companies',
                    title: 'Companies',
                    type: 'item',
                    url: '/companies',
                    breadcrumbs: false,
                },
                {
                    id: 'businessnumbers',
                    title: 'Business Numbers',
                    type: 'item',
                    url: '/business-numbers',
                    breadcrumbs: false,
                },
                {
                    id: 'distributionlist',
                    title: 'Distribution List',
                    type: 'item',
                    url: '/distribution-list',
                    breadcrumbs: false,
                },
                {
                    id: 'agentgroups',
                    title: 'Agent Groups',
                    type: 'item',
                    url: '/agent-groups',
                    breadcrumbs: false,
                },
                /*
                {
                    id: 'autoreplytexts',
                    title: 'Auto Replies (Text)',
                    type: 'item',
                    url: '/auto-reply-texts',
                    breadcrumbs: false,
                },
                */
                {
                    id: 'autoreplytime',
                    title: 'Auto Replies (Time)',
                    type: 'item',
                    url: '/auto-reply-times',
                    breadcrumbs: false,
                },
                {
                    id: 'apirequests',
                    title: 'API Requests',
                    type: 'item',
                    url: '/api-requests',
                    breadcrumbs: false,
                },
            ]
        },
        {
            id: 'data-parent',
            title: 'Data',
            type: 'collapse',
            icon: icons.DataArrayIcon,
            iconColor: '#252d64',
            breadcrumbs: false,
            children: [{
                id: 'mobileenduser',
                title: 'Mobile End Users',
                type: 'item',
                url: '/mobile-end-user',
                breadcrumbs: false,
                iconColor: '#000080'
            }]
        }
            
    ]
};

export {
    adminMenuItems,
    adminMenuItemsNoDelay,
    spAdminMenuItems,
    spAdminMenuItemsNoDelay
};