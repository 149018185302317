// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import APIGetRecords from 'utils/api/APIGetRecords';

// ----------------------------------------------------------------------
import useAuth from 'hooks/useAuth';
import notificationSound from '../../assets/sounds/notification.mp3'
import toast, { useToaster } from "react-hot-toast";

const HotToastNotifications = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;
  return (
    <div
      style={{
        position: "fixed",
        top: 10,
        left: 10,
        width:'100%'
      }}
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((toast) => {
        const offset = calculateOffset(toast.id, {
          reverseOrder: false,
          margin: 10
        });
        const ref = (el) => {
          if (el && !toast.height) {
            const height = el.getBoundingClientRect().height;
            updateHeight(toast.id, height);
          }
        };
        return (
          <div
            role="alert"
            aria-label="notification"
            key={toast.id}
            ref={ref}
            style={{
              position: "absolute",
              width: "13rem",
              padding: ".7rem",
              background: "rgba(175, 75, 62, 0.1)",
              
              transition: "all 0.2s",
              transform: `translateY(${offset}px)`,
              opacity: toast.visible ? 1 : 0,
              width:'100%'
            }}
          >
            {toast.message}
          </div>
        );
      })}
    </div>
  );
};

const audio = new Audio(notificationSound)

const initialState = {
    activeTab : 0,
    error: null,
    mobileenduser: {},
    notes :[],
    chats: [],
    openConversations: [],
    activeConversation:{},
    newCount :0,
    groups : [],
    agents : [],
    closed : [],
    loading : false,
    tabInFocus : false
};


const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: { 
        // HAS ERROR

        hasError(state, action) {
            state.error = action.payload;
        },
        showLoadingChat(state,action){
            state.loading = action.payload;
        },
        markMessageRead(state,action){       
            if (action.payload.conversation_type === 'agent' || action.payload.conversation_type === 'group') {
                if (action.payload.clicked_by_agent_id === action.payload.current_agent_id) {
                    state.openConversations =  state.openConversations.map((content) => parseInt(content.id) === parseInt(action.payload._id) ? {...content, read_by_agent_id: `${content.read_by_agent_id},${action.payload.current_agent_id}`}  : content); 
                    const chats =  state.openConversations.filter(conversation =>  parseInt(conversation.conversation_type === 'agent' || conversation.conversation_type === 'group' ? isInCommaDelimitString(conversation.read_by_agent_id, action.payload.current_agent_id) : conversation.msgstat) === 0) ;

                    state.newCount = chats.length;
                }
            }
            else {
                state.openConversations =  state.openConversations.map((content) => parseInt(content.id) === parseInt(action.payload._id) ? {...content, msgstat : 2}  : content); 
                const chats =  state.openConversations.filter(conversation =>  parseInt(conversation.conversation_type === 'agent' || conversation.conversation_type === 'group' ? isInCommaDelimitString(conversation.read_by_agent_id, action.payload.current_agent_id) : conversation.msgstat) === 0) ;
                state.newCount = chats.length;
            }
        },
        setClosed(state,action){
           state.closed = action.payload;
        },
        resetConversation(state, action){
            state.activeTab = 0;
            state.error = null;
            state.mobileenduser = {};
            state.notes = [];
            state.chats = [];
            state.openConversations = [];
            state.activeConversation = {};
            state.newCount = 0;
            state.groups = [];
            state.agents = [];
            state.closed = [];  
        },
        setNewCount(state,action) {
            state.newCount = action.payload;
        },
        updateNewCount(state,action){
            const chats =  state.openConversations.filter(conversation => parseInt(conversation.msgstat) === 0) ;
            state.newCount = chats.length;
        },
        setAgents(state,action) {
            state.agents = action.payload;
        },
        setAgentGroups(state,action) {
            state.groups = action.payload;
        },
        setActiveTab(state,action){
            state.activeTab = action.payload;
        },
        setNotes(state,action) {
            state.notes = action.payload;
        },
        setMobileEndUser(state,action){
            state.mobileenduser = action.payload;
        },
        setTabFocus(state, action) {
            state.tabInFocus = action.payload
        },
        // GET USER
        getUserSuccess(state, action) {
            state.user = action.payload;
        },


        setConversationChats(state, action) {
            state.chats = action.payload;
            state.notes = action.payload.filter(chats => parseInt(chats.chatType) === parseInt(2))    // chatType 2 Notes
        },

        addMessageToChat(state,action){
            state.chats =  state.chats.filter(chats => parseInt(chats._id) !== parseInt(action.payload._id)) ;
            state.chats = [...state.chats, action.payload];
            state.notes = state.chats.filter(chats => parseInt(chats.chatType) === parseInt(2))   // chatType 2 Notes
           // console.log(state.chats)

        },
        updateMessageState(state,action){

            state.chats = state.chats.map((content, _id) => parseInt(content._id) === parseInt(action.payload._id) ? {...content, new_state : action.payload.new_state}
                                        : content);
 

        },
        setOpenConversation(state, action) {
            state.openConversations = action.payload;
            const chats =  state.openConversations.filter(conversation => parseInt(conversation.msgstat) === 0) ;
            state.newCount = chats.length;
        },
        updateOpenConversationOwnership(state,action){

        if (action.payload.wtd === 'U') {    
        
            state.openConversations = state.openConversations.map((content, id) => parseInt(content.id) === parseInt(action.payload.id) ?
             {
                 ...content, 
                 agent_id : action.payload.agent_id, 
                 agent_name : action.payload.agent_name,
                 group_id : action.payload.group_id,
                 group_name : action.payload.group_name,
                 hex : action.payload.hex
            }
            : content);
        } 
        else if (action.payload.wtd === 'D') {  
            state.openConversations =  state.openConversations.filter(conversation => parseInt(conversation.id) !== action.payload.id) ;
        }
        else if (action.payload.wtd === 'I') {  
            state.openConversations = [action.payload, ...state.openConversations];
//add sort here
           }
        
        },
        updateOpenConversation(state, action){

            const emptyDrop = (data) => {
   
            }

            const b4chats =  state.openConversations.filter(conversation =>  parseInt(conversation.conversation_type === 'agent' || conversation.conversation_type === 'group' ? isInCommaDelimitString(String(conversation.read_by_agent_id), action.payload.current_agent_id) : conversation.msgstat) === 0) ;
            
            //remove payload conversation from list, and then append new one to be listed on top
            const removed =  state.openConversations.filter(conversation => parseInt(conversation.id) !== parseInt(action.payload.id));
            state.openConversations = [action.payload, ...removed];
            const chats =  state.openConversations.filter(conversation =>  parseInt(conversation.conversation_type === 'agent' || conversation.conversation_type === 'group' ? isInCommaDelimitString(String(conversation.read_by_agent_id), action.payload.current_agent_id) : conversation.msgstat) === 0) ;

            if (action.payload.conversation_type === 'agent' || action.payload.conversation_type === 'group') {
                if (action.payload.by_agent_id !== action.payload.current_agent_id) { // Was the new message sent by the current agent?
                    if (!state.tabInFocus) {
                        if (action.payload.enable_notification_sound) {
                            audio.play()  
                        }

                        if (action.payload.enable_desktop_notification) {
                            new Notification(`New message from ${action.payload.by_agent_name}`, {body: action.payload.message_body})
                        }
                    }

                    if (state.activeConversation.id !== action.payload.id) { // Message was not sent by the current agent, but does the recipient agent have the conversation opened?
                        toast("🛎 New message received !")
                        state.newCount = chats.length;
                    }
                    else { // recipient agent has the conversation opened
                        action.payload.msgstat = 2
                        action.payload.read_by_agent_id = action.payload.current_agent_id

                        let options = {PostSuccessFunction: emptyDrop, supressNotFound:true, url: `/conversations/messages/conv_read/${action.payload.id}` };
                        APIGetRecords(options);
                    }
                }
                else { // Message was sent by the current agent
                    action.payload.msgstat = 2
                    action.payload.read_by_agent_id = action.payload.current_agent_id
                }
            }
            else { // Message is SMS/WA, treat as normal
                if (!state.tabInFocus && action.payload.eventType === "mo_message_received") {
                    if (action.payload.enable_notification_sound) {
                        audio.play()  
                    }
                    
                    if (action.payload.enable_desktop_notification) {
                        new Notification(`New SMS from ${action.payload.name} (${action.payload.mobileenduser})`, {body: action.payload.message_body})
                    }
                }

                if (b4chats.length < chats.length) {
                    toast("🛎 New message received !")
                }

                state.newCount = chats.length;
            }               
        },
        setActiveConversation(state, action){
            state.activeConversation = action.payload;
        },

        closeConversation(state, action){      
            state.openConversations = state.openConversations.filter(conversation => parseInt(conversation.id) !== parseInt(action.payload))        
        }          
    }
});

// Reducer
export default slice.reducer;

export function resetChat(){
    return ()=>{
        dispatch(slice.actions.resetConversation());
    }
}
export function markMessageRead(id){
    return async () => {
        try {
            dispatch(slice.actions.markMessageRead(id));
               
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
    
}
// ----------------------------------------------------------------------

export function setActiveTab(value)
{
    return async () => {
        try {
            dispatch(slice.actions.setActiveTab(value));   
                   
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}

export function setTabFocus(data) {
    return async () => {
        try {
            dispatch(slice.actions.setTabFocus(data))
        }
        catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function updateOpenConversationOwnership(data){
    return async () => {
        try {
            dispatch(slice.actions.updateOpenConversationOwnership(data));   
                   
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateOpenConversation(value)
{
    var data = value;
    data.name = (!data.name || data.name.trim() === '') ?  data.mobileenduser : data.name;

    return async () => {
        try {
            dispatch(slice.actions.updateOpenConversation(data));   
                   
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}

export function setAgentGroups(value)
{
    return async () => {
        try {
            dispatch(slice.actions.setAgentGroups(value));   
                   
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}

export function setAgents(value)
{
    return async () => {
        try {
            dispatch(slice.actions.setAgents(value));   
                   
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}
export function closeConversation(id){
    return async () => {
        try {
            dispatch(slice.actions.closeConversation(id));   
                   
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}

export function updateMessageState(message){
    return async () => {
        try {
            dispatch(slice.actions.updateMessageState(message));   
                   
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };     
}

export function addMessageToChat(message){
    return async () => {
        try {
            dispatch(slice.actions.addMessageToChat(message));   
                   
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }; 
}


export function setActiveConversation(conversation){
    return async () => {
        try {
            dispatch(slice.actions.setActiveConversation(conversation));           
        
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}
export function setConversationChats(data) {
    return async () => {
        try {
            dispatch(slice.actions.setConversationChats(data));           
        
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getConversationChats(id) {

    const processSuccess = (data)=> {
       
        if (data.error){
            toast.error(data.emessage);
            dispatch(slice.actions.setActiveConversation({}));  
        }
        else{
           if (data.chats && !data.chats.code) 
               dispatch(slice.actions.setConversationChats(data.chats));
           if (data.meu && !data.meu.code)
               dispatch(slice.actions.setMobileEndUser(data.meu));
           if (data.agents && !data.agents.code){
            var arData = [{lbl:'No Assigned Agent',val:0}]; 
            arData = [...arData,...data.agents];     
              dispatch(slice.actions.setAgents(arData));
           }
           if (data.agroups && !data.agroups.code){
            var arData1 = [{lbl:'No Assigned Group',val:0}];
            arData1 = [...arData1,...data.agroups];
              dispatch(slice.actions.setAgentGroups(arData1)); 
           }
           
           if (data.closed){
               if (data.closed.code) {
                   toast.error(data.closed.message);
                   dispatch(slice.actions.setClosed([])); 
               }
               else
                  dispatch(slice.actions.setClosed(setDisplayValues(data.closed))); 
           }
         
        }
        
    }

    return async () => {
        try {
            if (id){
            //let options = { setValue: processSuccess,supressNotFound:true, url: `/conversations/messages/conv/${id}` };
            let options = { setValue: processSuccess,supressNotFound:true, url: `/conversations/messages/conv/${id}` };
            dispatch(slice.actions.showLoadingChat(true));
            await APIGetRecords(options);
            dispatch(slice.actions.showLoadingChat(false));

            
        }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

function setDisplayValues(data){
    
    return   data.map((content, _id) => (!content.name || content.name.trim() === '') ? {...content, name : content.mobileenduser}
    : content);

}

const isInCommaDelimitString = (hayStack,needle) => {
	//const {user} = useAuth();

    var retval = 0;

    if (hayStack !== undefined && hayStack) {
        var splitString = hayStack.split(',');

        for (var i = 0; i < splitString.length; i++) {
			var item = splitString[i];
			if (parseInt(item) !== parseInt(needle)) continue;

			retval = 1;
			break;
    	}
	}

    return retval;
}

export function loadOpenConversation(agent_id) {

    

    const processSuccess = (data)=> {
       // console.log(data);
       // console.log("Length : " + JSON.stringify(data).length)
         dispatch(slice.actions.setOpenConversation(setDisplayValues(data)));
         dispatch(slice.actions.setNewCount(data.filter(chats => parseInt(chats.conversation_type === 'agent' || chats.conversation_type === 'group' ? isInCommaDelimitString(chats.read_by_agent_id, agent_id) : chats.msgstat) === 0).length));
    }
    return async () => {
        try {
            let options = { setValue: processSuccess, supressNotFound:true, url: `/conversations/messages/openconv/${agent_id}` };            
            await APIGetRecords(options);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function loadConversationNotes(conversation_id){

    const processSuccess = (data)=> {
         dispatch(slice.actions.setNotes(data));
    }
    return async () => {
        try {
            let options = { setValue: processSuccess,supressNotFound:true, url: `/conversations/records/conversation-notes/${conversation_id}` };
            await APIGetRecords(options);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };    
}

export function loadMobileEndUser(mobileenduser_id){

    const processSuccess = (data)=> {
         dispatch(slice.actions.setMobileEndUser(data));
    }
    return async () => {
        try {
            
            const options = { url: `/conversations/records/mobile-end-user/` + mobileenduser_id, setValue: processSuccess, singleRecord: true , supressNotFound:true};
            APIGetRecords(options);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setMobileEndUser(data){
    return async () => {
        try {
            dispatch(slice.actions.setMobileEndUser(data));           
        
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}