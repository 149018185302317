import { ConstructionOutlined } from '@mui/icons-material'
import {DateTime, Interval} from 'luxon'

export const getLocaleDate = (user, isoString) => {
  
    const luxonDate = DateTime.fromISO(isoString, {zone: "UTC"})
    const convertedDate = luxonDate.setZone(user.timezone_id)
   
    return convertedDate.toLocaleString(DateTime.DATETIME_SHORT)
}

export  const convertUTCDateToLocalDate = (date_utc_iso) =>{

    const dt = new Date();
    const offset = (dt.getTimezoneOffset()/60);
    const newDate = DateTime.fromISO(date_utc_iso, { zone: 'utc' }).plus({hours : (offset*-1)});
    return newDate;
}

export const ConvertDateTimeToAGO = (luxon_datetime) => {

    const formatSentance = (amnt,desc)=>{        
        return `${amnt} ${amnt === 1 ? desc + ' ago' : desc + 's ago'}`;
    }

    const dt = new Date();
    const offset = (dt.getTimezoneOffset()/60);

    const now = DateTime.now().plus({hours : (offset*-1)});
    const msg = luxon_datetime;

    const diff = Interval.fromDateTimes(msg, now);
    const hours   = Math.trunc(diff.length('hours')); 
    const minutes = Math.trunc(diff.length('minutes')); 
    const days    = Math.trunc(diff.length('days')); 
    const weeks    = Math.trunc(diff.length('weeks')); 
    const months  = Math.trunc(diff.length('months'));
    const years   = Math.trunc(diff.length('years')); 
    const seconds   = Math.trunc(diff.length('seconds'));        
    
    
    var retval = '';
    
    if (years > 0)  retval = formatSentance(years,'year');
    else if (months > 0) retval = formatSentance(months,'month');
    else if (weeks > 0) retval = formatSentance(weeks,'week');
    else if (days > 0) retval = formatSentance(days,'day');
    else if (hours > 0) retval = formatSentance(hours,'hour');
    else if (minutes > 0) retval = formatSentance(minutes,'minute');
    else if (seconds > 0) retval = formatSentance(seconds,'second');

   
    return retval;
}

export const isDST = () => {
    var retval = false
    var date = DateTime.now().toUTC()
    date = date.setZone('America/New_York')

    if (date.o === -240) {
        retval = true
    }

    return retval
}
