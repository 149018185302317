// material-ui

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

import logo from 'assets/images/logos/Connections_1024.png';
//import logo from 'assets/images/logos/sharelifelogo.png';
//import logo from 'assets/images/logos/SPI-Software-Logo-UI.jpg';
import useAuth from 'hooks/useAuth';


// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    
    const {user} = useAuth();
    var logourl = logo;
    var company_name = '';

    if (user && user.logourl && user.logourl !=='')
       logourl = user.logourl;


    return (
      <img src={logourl} alt={company_name} width="180" height='40'  />
    );
};

export default Logo;
