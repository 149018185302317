import { useEffect, useRef, useState } from 'react';
import { getLocaleDate } from 'utils/date/getLocaleDate';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Badge,
    Button,
    CardActions,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,

    useMediaQuery
} from '@mui/material';
import Pusher from "pusher-js";

import useAuth from 'hooks/useAuth';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import {useDispatch, useSelector } from 'store';

// assets
import { IconBell } from '@tabler/icons';


// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = ({loadOpenConversation,updateOpenConversation}) => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const [newConv, setNewConv] = useState([]);

    const dispatch = useDispatch();
    const chatState  = useSelector((state) => state.chat);
    const {user} = useAuth();

    const navigate = useNavigate();

    /**
     * 
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     *  
     */

    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);

    const isInCommaDelimitString = (hayStack, needle, intMode = false) => {
        var retval = intMode ? 0 : false;

        if (hayStack !== undefined && hayStack) {
            var splitString = hayStack.split(',');

            for (var i = 0; i < splitString.length; i++) {
                var item = splitString[i];

                if (parseInt(item) !== parseInt(needle)) continue;

                retval = intMode ? 1 : true;
                break;
            }
        
        }
        return retval;
    }

    

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;

        if (user && user._id){           
            dispatch(loadOpenConversation(user._id)); 
        }

        if (chatState) {
            const newc = chatState.openConversations.filter(chats => parseInt(chats.conversation_type === 'agent' || chats.conversation_type === 'group' ? isInCommaDelimitString(String(chats.read_by_agent_id), user?._id, 1) : chats.msgstat) === 0);
            setNewConv(newc);
        }

        let mounted = true;

        if (mounted) {
            var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            });

            var channel = pusher.subscribe("conversationPage");

            channel.bind("newEvent", function (data) {                                                           
                if (data.action && data.action === "updateList" && data.what && data.what === "webHookEvent") {
                    if (user && user._id) {
                        
                        var bUpdateList = (data.data !== undefined && data.data.agent_id !== undefined && user !== undefined);
                        
                        if (bUpdateList) {
                            bUpdateList = (user.company_id === data.data.company_id) 
                            
                            if (bUpdateList) {
                                bUpdateList = (data.data.conversation_type === 'agent' && data.data.mobileenduser_id === user._id) || (data.data.conversation_type === 'group' && isInCommaDelimitString(user.groups,data.data.mobileenduser_id)) || (data.data.agent_id === 0 && data.data.group_id === 0) || (data.data.agent_id === user._id) || isInCommaDelimitString(user.groups,data.data.group_id)
                            }                                                        
                        }

                        if (bUpdateList) {
                            var data1 = data.data;         
                            data1.time = getLocaleDate(user, data.data.time);
                            data1.current_agent_id = user._id
                            data1.enable_notification_sound = user.enable_notification_sound
                            data1.enable_desktop_notification = user.enable_desktop_notification
                            data1.by_agent_id = data.message.by_agent_id
                            data1.by_agent_name = data.message.by_agent_name
                            data1.message_body = data.message.message_body
                            data1.eventType = data.message.eventType

                            dispatch(updateOpenConversation(data1))
                        }   
                    }        
                }
            });
        }

        return () => {
            mounted = false;
            //console.log("Unsubscribing pusher");
            pusher.unsubscribe("conversationPage");
        };
    }, [dispatch, open, user]);

    useEffect(() => {
        document.title =  chatState.newCount > 0 ? `(${chatState.newCount}) Connections - Conversations` : 'Connections - Conversations';
    }, [chatState.newCount]);


    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            ><Badge color="primary" badgeContent={chatState ? chatState.newCount : 0} invisible={chatState && chatState.newCount < 1}>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                 
                    <IconBell stroke={1.5} size="1.3rem" />
                   
                </Avatar>
                </Badge>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Grid container direction="column" spacing={2}>
                                        
                                            <Grid item xs={12}>
                                                <PerfectScrollbar
                                                    style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                                                >
                                               
                                                    <NotificationList conversationChat={newConv} setOpen={setOpen} />
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {newConv.length > 0 ?
                                        <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Button size="small" disableElevation onClick={()=>{navigate('/chat');setOpen(false);}}>
                                                Go to Conversation
                                            </Button>
                                        </CardActions>
                                        :
                                        <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                        <Button size="small" disableElevation  onClick={()=>{setOpen(false);}} >
                                            No Notifications
                                        </Button>
                                    </CardActions>}
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
