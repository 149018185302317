import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';
import LocationCityTwoToneIcon from '@mui/icons-material/LocationCityTwoTone';
import QuickreplyTwoToneIcon from '@mui/icons-material/QuickreplyTwoTone';
import NotesTwoToneIcon from '@mui/icons-material/NotesTwoTone';
import NewspaperTwoToneIcon from '@mui/icons-material/NewspaperTwoTone';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'store';
import {resetChat, setTabFocus} from 'store/slices/chat';
import Pusher from "pusher-js";
// assets
import { IconLogout, IconSettings } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import preval from 'preval.macro';
import APIDeleteRecord from 'utils/api/APIDeleteRecord';

import {version} from './../../../../../package.json'

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { borderRadius } = useConfig();
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { logout, user } = useAuth();
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    // User has switched back to the tab
    const onFocus = () => {
        dispatch(setTabFocus(true))
    };

    // User has switched away from the tab (AKA tab is hidden)
    const onBlur = () => {
        dispatch(setTabFocus(false))
    };

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        // Calls onFocus when the window first loads
        onFocus();
        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, [])

    useEffect(() => {
        if (("Notification" in window)) {
            if (Notification.permission !== "denied") {
                Notification.requestPermission()
            }
        }
    }, [])

    useEffect(() => {
        let mounted = true;
            if (mounted) {
                var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
                });

                var channel = pusher.subscribe("forceLogOut");

                channel.bind("logOut", function (data) {
                    if (data.agent_id && parseInt(data.agent_id) === user._id) {
                        handleLogout()
                    }
                });
            }

            return () => {
                mounted = false;
                //console.log("Unsubscribing pusher");
                pusher.unsubscribe("forceLogOut");
            };
    }, [])

    const loggedOff=()=>{
           //console.log("Logged off successfully");
    }
    const logoffFromServer=async()=>{

        const delOptions = { url: `/login/${user._id}` ,PostSuccessFunction: loggedOff };
        await APIDeleteRecord(delOptions);
    }

    const handleLogout = async () => {
        try {
            await logoffFromServer();
            dispatch(resetChat());
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const partOfDay=()=>{
        const hour = (new Date()).getHours();
        if (hour < 12)
           return 'Morning';
        else if (hour < 18)
           return 'Afternoon';
        else
           return 'Evening';
    }

    const handleClose = (event) => {
  
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                      
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 2, pb: 0 }}>
                                            <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography variant="welcome">Good {partOfDay()},</Typography>
                                                    <Typography component="span" variant="welcome" sx={{ fontWeight: 400 }}>
                                                        {user?.screenname}
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="subtitle2">{user?.email}</Typography>
                                            </Stack>
                                        </Box>
                                        <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                            <Box sx={{ p: 2, pt: 0 }}>
                                                <List
                                                    component="nav"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 350,
                                                        minWidth: 300,
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '10px',
                                                        [theme.breakpoints.down('md')]: {
                                                            minWidth: '100%'
                                                        },
                                                        '& .MuiListItemButton-root': {
                                                            mt: 0.5
                                                        }
                                                    }}
                                                >
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 0}
                                                        onClick={(event) => handleListItemClick(event, 0, '/agent/profile')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconSettings stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                    </ListItemButton>
                                                    <Grid hidden={!user || (parseInt(user.roles_id) === 100)}>
                                                     <ListItemButton visible={false}
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 0}
                                                        onClick={(event) => handleListItemClick(event, 0, '/company/profile')}
                                                    >
                                                        <ListItemIcon>
                                                            <LocationCityTwoToneIcon stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Company Profile</Typography>} />
                                                    </ListItemButton>
                                                    <ListItemButton visible={false}
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 0}
                                                        onClick={(event) => handleListItemClick(event, 0, '/quick-replies')}
                                                    >
                                                        <ListItemIcon>
                                                            <QuickreplyTwoToneIcon stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Quick Replies</Typography>} />
                                                    </ListItemButton>
                                                    <ListItemButton visible={false}
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 0}
                                                        onClick={(event) => handleListItemClick(event, 0, '/quick-notes')}
                                                    >
                                                        <ListItemIcon>
                                                            <NotesTwoToneIcon stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Quick Notes</Typography>} />
                                                    </ListItemButton>


                                                    {
                                                        user && user.service_provider ?
                                                            <ListItemButton visible={false}
                                                                sx={{ borderRadius: `${borderRadius}px` }}
                                                                selected={selectedIndex === 0}
                                                                onClick={(event) => handleListItemClick(event, 0, '/logs')}
                                                            >
                                                                <ListItemIcon>
                                                                    <NewspaperTwoToneIcon stroke={1.5} size="1.3rem" />
                                                                </ListItemIcon>
                                                                <ListItemText primary={<Typography variant="body2">System Logs</Typography>} />
                                                            </ListItemButton>
                                                        :
                                                            ''
                                                        
                                                    }
                                                    
                                                    </Grid>
                                                    
                                                   
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleLogout}
                                                    >
                                                        <ListItemIcon>
                                                            <IconLogout stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                    </ListItemButton>
                                                </List>
                                                <Typography variant="subtitle2" align="center"> Build Version: {version}</Typography>
                                                <Typography variant="subtitle2" align="center"> Build Date: {preval`module.exports = new Date().toLocaleString();`}</Typography>
                                            </Box>
                                        </PerfectScrollbar>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
