import toast from "react-hot-toast";

const PerformRecordValidation = async (data, options) => {
    if (data.code) {
        if (data.code === 100 || data.code === "100") {
            if (options.PostSuccessFunction)
                return await options.PostSuccessFunction(data);
            else { toast.success(data.message); return true; }

        }
        if (data.code === -100 || data.code === "-100") {
            if (options.PostWarningFunction)
                return await options.PostWarningFunction(data);
            else { toast.success(data.message); return true; } 

        }
        else {
            if (options.PostErrorFunction)
                return await options.PostErrorFunction(data);
            else {
                toast.error(data.status + " : " + data.message);
                return true;
            }
        }
    }
    else if (options.params) {
        return await options.params.api.applyTransaction({ add: data });
    }
    else if (options.setValue) {
        if (options.singleRecord) { options.setValue(data[0]); return true; }
        else { options.setValue(data); return true; }
    }
    else { toast.error("No Action Specified for data process."); return true; }

}
export default PerformRecordValidation;